.intersts{
    width: 100vw;
    height: 280vh;
    background-color: rgb(250, 250, 250);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

}
.interstsTitle {
    font-size: 40px;
    font-family:Arial, Helvetica, sans-serif;
    letter-spacing: 6px;
}

.interstsFirstPage {
    width: 90%;
    height: 50vh;
    margin: 0 5% 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.interstContainerBio {
    width: 40vw;
    height: 35vw;
    background-color: rgb(0, 0, 0);
    box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.35);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.interstTextBio {
    color:white;
    width: 90%;
    height: 40%;
}

.interstTextTitleBio {
    font-size: 40px;
    font-family: 'Montserrat', sans-serif;
}

.interstTextDescriptionBio {
    font-size: 18px;
}

.pictureOfCopilotForProtein {
    width: 60%;
  
}


.interstsClimateChange {
    background-color: black;
    background-color: rgb(0, 0, 0); 
    box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.35);
    border-radius: 15px;
    color: white;
    width: 35vw;
    height: 35vw;
    padding: 20px;
}

.interstsClimateChangeText {
    width: 90%;
    margin:  5%;
    
}

.interstsClimateChangeText h3 {
    color: rgb(35, 193, 75);
    font-size: 40px;
    font-family: 'Montserrat', sans-serif;
}

.deepLearningImage {
    width: 70%;
    margin: 0 15%;
    border-radius: 10px;
}



.interstsSecondPage {
    width: 100%;
}

.interstsQuantumComputingContainer{
    background-color: rgb(0, 0, 0); 
    box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.35);
    border-radius: 15px;
    width: 80%;
    height: 65vh;
    margin: 0 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

}


.interstsQuantumComputingText {
    width: 80%;
    margin: 0 10%;
    margin-top: 20px;
}

.interstsQuantumComputingText h3 {
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 45px;
}

.interstsQuantumComputingText h3 span {
    color: gold;
}


.interstsQuantumComputingText p {
    color: rgb(220, 220, 220);
}



.interstsQuantumComputingPictures {
    width: 90%;
    margin: 0 5%;
    display: flex;
    align-items: end;
    justify-content: space-between;
    margin-bottom: 20px;
}

.qubitPicture {
    width: 25%;
    border-radius: 10px;
}



.quantumDeepLearningPicture {
    width: 25%;
    border-radius: 10px;
}

.quantumComputerPicture {
    width: 25%;
    border-radius: 10px;
}



.quantum3DModelContainer {
    width: 60%;
    position: relative;
}


  /* Media query for large devices (min-width: 1200px and max-width: 1449px */
  @media screen and (min-width: 1400px) and (max-width: 1800px) {
    .interstsTitle {
        font-size: 38px;
        letter-spacing: 5px;
    }

    .interstTextBio {
        color:white;
        width: 90%;
        height: 40%;
    }
    .interstTextTitleBio {
        font-size: 28px;
        font-family: 'Montserrat', sans-serif;
    }

    .interstTextDescriptionBio {
        font-size: 14px;
    }

    .pictureOfCopilotForProtein {
        width: 50%;
      
    }


    .interstsClimateChange {
        padding: 18px;
        height: 36vw;
    }

    .interstsClimateChangeText h3 {

        font-size: 27px;
    }
    
    .deepLearningImage {
        width: 50%;
        margin: 0 25%;
        border-radius: 10px;
    }
  
    .interstsSecondPage {
        width: 100%;
    }
    
    .interstsQuantumComputingContainer{
        height: 75vh;
    
    }

    .interstsQuantumComputingText {
        margin-top: 10px;
    }
    
    .interstsQuantumComputingText h3 {
        font-size: 39px;
    }


    .interstsQuantumComputingPictures {
        width: 90%;
        margin: 0 5%;
        display: flex;
        align-items: end;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    
    .qubitPicture {
        width: 24%;

    }

    .quantumDeepLearningPicture {
        width: 24%;

    }
    .quantumComputerPicture {
        width: 24%;
        border-radius: 10px;
    }
  }

    /* Media query for large devices (min-width: 1200px and max-width: 1449px */
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .interstsTitle {
        font-size: 32px;
        letter-spacing: 5px;
    }

    .interstTextBio {
        color:white;
        width: 90%;
        height: 40%;
    }
    .interstTextTitleBio {
        font-size: 24px;
        font-family: 'Montserrat', sans-serif;
    }

    .interstTextDescriptionBio {
        font-size: 13px;
    }

    .pictureOfCopilotForProtein {
        width: 50%;
      
    }


    .interstsClimateChange {
        padding: 16px;
        height: 36vw;
    }

    .interstsClimateChangeText h3 {

        font-size: 25px;
    }
    
    .deepLearningImage {
        width: 50%;
        margin: 0 25%;
        border-radius: 10px;
    }
  
    .interstsSecondPage {
        width: 100%;
    }
    
    .interstsQuantumComputingContainer{
        height: 75vh;
    
    }

    .interstsQuantumComputingText {
        margin-top: 10px;
    }
    
    .interstsQuantumComputingText h3 {
        font-size: 36px;
    }


    .interstsQuantumComputingPictures {
        width: 90%;
        margin: 0 5%;
        display: flex;
        align-items: end;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    
    .qubitPicture {
        width: 24%;

    }

    .quantumDeepLearningPicture {
        width: 24%;

    }
    .quantumComputerPicture {
        width: 24%;
        border-radius: 10px;
    }
}

  /* Media query for large devices (min-width: 992px and max-width: 1199px) */
  @media screen and (min-width: 1000px) and (max-width: 1199px) {
   
    .interstsTitle {
        font-size: 28px;
        letter-spacing: 5px;
    }
    .interstContainerBio {
        height: 42vw;

    }
    .interstTextBio {
        color:white;
        width: 90%;
        height: 40%;
    }
    .interstTextTitleBio {
        font-size: 20px;
        font-family: 'Montserrat', sans-serif;
    }

    .interstTextDescriptionBio {
        font-size: 13px;
    }

    .pictureOfCopilotForProtein {
        width: 50%;
    }


    .interstsClimateChange {
        padding: 14px;
        height: 45vw;
    }

    .interstsClimateChangeText h3 {

        font-size: 22px;
    }
    
    .deepLearningImage {
        width: 50%;
        margin: 0 25%;
        border-radius: 10px;
    }
  
    .interstsSecondPage {
        width: 100%;
    }
    .interstsQuantumComputingContainer{
        height: 80vh;
    }

    .interstsQuantumComputingText {
        margin-top: 10px;
    }
    
    .interstsQuantumComputingText h3 {
        font-size: 30px;
    }


    .interstsQuantumComputingPictures {
        width: 90%;
        margin: 0 5%;
        display: flex;
        align-items: end;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    
    .qubitPicture {
        width: 22%;
    }

    .quantumDeepLearningPicture {
        width: 22%;

    }
    .quantumComputerPicture {
        width: 22%;
        border-radius: 10px;
    }

  }
  
  /* Media query for medium devices (min-width: 768px and max-width: 991px) */
  @media screen and (min-width: 800px) and (max-width: 999px) {
    .intersts {
        height: 160vh;
        justify-content: flex-start;
    }
    .interstsTitle {
        font-size: 28px;
        letter-spacing: 5px;
    }
    .interstsFirstPage {
        flex-direction: column;
        width: 100vw;
        margin-bottom: 500px;
    }
    
    .interstContainerBio {
        width: 90vw;
        height: 60vw;
        margin: 0 5vw;
    }
    .interstTextBio {
        color:white;
        width: 90%;
        height: 40%;
    }
    .interstTextTitleBio {
        font-size: 20px;
        font-family: 'Montserrat', sans-serif;
    }

    .interstTextDescriptionBio {
        font-size: 13px;
    }

    .pictureOfCopilotForProtein {
        width: 50%;
    }


    .interstsClimateChange {
        width: 90vw;
        height: 60vw;
        margin: 0 5vw;
        padding: 0;
    }

    .interstsClimateChangeText h3 {

        font-size: 22px;
    }
    
    .deepLearningImage {
        width: 50%;
        margin: 0 25%;
        border-radius: 10px;
    }
  
    .interstsSecondPage {
        width: 100%;
        height: 60vh ;
    }
    .interstsQuantumComputingContainer{
        width: 90vw;
        margin:  0 5vw;
        height: 50vh;
        margin-bottom: 0;
    }

    .interstsQuantumComputingText {
        margin-top: 10px;
    }
    .interstsQuantumComputingText h3 {
        font-size: 30px;
    }


    .interstsQuantumComputingPictures {
        width: 90%;
        margin: 0 5%;
        display: flex;
        align-items: end;
        justify-content: space-between;
        margin-bottom: 0;
    }
    
    .qubitPicture {
        width: 24%;
    }

    .quantumDeepLearningPicture {
        width: 24%;

    }
    .quantumComputerPicture {
        width: 24%;
        border-radius: 10px;
    }

  }
  
  /* Media query for small devices (min-width: 576px and max-width: 767px) */
  @media screen and (min-width: 650px) and (max-width: 799px) {
    .intersts {
        height: 220vh;
        justify-content: flex-start;
    }
    .interstsTitle {
        font-size: 18px;
        letter-spacing: 2px;
    }
    .interstsFirstPage {
        flex-direction: column;
        width: 100vw;
        margin-bottom: 500px;
    }

    .interstContainerBio {
        width: 90vw;
        height: 90vw;
        margin: 0 5vw;
        margin-bottom: 50px;
    }

    .interstTextBio {
        color:white;
        width: 90%;
        height: 40%;
    }
    .interstTextTitleBio {
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
    }

    .interstTextDescriptionBio {
        font-size: 10px;
    }

    .pictureOfCopilotForProtein {
        width: 50%;
    }


    .interstsClimateChange {
        width: 90vw;
        height: 110vw;
        margin: 0 5vw;
        padding: 10px 0 0;
    }
    .interstsClimateChangeText h3 {
        font-size: 14px;
    }
    
    .deepLearningImage {
        display: none;
    }


    .interstsSecondPage {
        width: 100%;
        height: 100vh ;
    }

    .interstsQuantumComputingContainer{
        width: 90vw;
        margin:  0 5vw;
        height: 85vh;
        margin-bottom: 0;
    }

    .interstsQuantumComputingText {
        margin-top: 10px;
    }

    .interstsQuantumComputingText h3 {
        font-size: 22px;
    }


    .interstsQuantumComputingPictures {
        width: 90%;
        margin: 0 5%;
        display: flex;
        align-items: end;
        justify-content: space-between;
        margin-bottom: 0;
        flex-direction: column;
    }

    .qubitPicture {
        width: 80%;
        margin: 0 10% 10px;
    }

    .quantumDeepLearningPicture {
        width: 24%;
        display: none;
    }
    .quantumComputerPicture {
        width: 24%;
        border-radius: 10px;
        display: none;
    }
}
  
  /* Media query for extra small devices (max-width: 575px) */
  @media screen and (max-width: 650px) {
    .intersts {
        height: 255vh;
        justify-content: flex-start;
        gap:0;
    }
    .interstsTitle {
        font-size: 16px;
        letter-spacing: 2px;
    }
    .interstsFirstPage {
        flex-direction: column;
        width: 100vw;
        height: 125vh;
        margin-bottom: 10px;
    }

    .interstContainerBio {
        width: 90vw;
        height: 55vh;
        margin: 0 5vw;
        margin-bottom: 50px;
    }

    .interstTextBio {
        color:white;
        width: 90%;
        height: 40%;
    }
    .interstTextTitleBio {
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
    }

    .interstTextDescriptionBio {
        font-size: 10px;
    }

    .pictureOfCopilotForProtein {
        width: 50%;
    }


    .interstsClimateChange {
        width: 90vw;
        height: 60vh;
        margin: 0 5vw;
        padding: 10px 0 0;
    }
    .interstsClimateChangeText h3 {
        font-size: 13px;
    }
    
    .deepLearningImage {
        display: none;
    }


    .interstsSecondPage {
        height: 100vh;
        width: 100%;
        justify-content: flex-start;
    }

    .interstsQuantumComputingContainer{
        width: 90vw;
        margin:  0 5vw;
        height: 90vh;
        margin-bottom: 0;
        justify-content: flex-start;
        gap: 20px;
    }

    .interstsQuantumComputingText {
        margin-top: 10px;
    }

    .interstsQuantumComputingText h3 {
        font-size: 18px;
    }


    .interstsQuantumComputingPictures {
        width: 90%;
        margin: 0 5%;
        display: flex;
        align-items: start;
        justify-content: space-between;
        margin-bottom: 0;
        flex-direction: column;
    }

    .qubitPicture {
        width: 80%;
        margin: 0 10% 10px;
    }

    .quantumDeepLearningPicture {
        width: 24%;
        display: none;
    }
    .quantumComputerPicture {
        width: 24%;
        border-radius: 10px;
        display: none;
    }
  }


