/* HeroSection.css */

.heroContainer {
    background-color: #000000; /* Black background for a clean minimalistic look */
    color: #ffffff; /* White text for contrast */
    width: 100%; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0;
    overflow: hidden;
    margin: 0 5%;
    font-size: 25px;
  }
  
  .textWrapper {
    max-width: 800px; /* Restrict max-width for large screens */
    width: 100%; /* Full width on small screens */
  }
  
  .headingHeroCoding {
    font-size: 3rem;
    margin-bottom: 20px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Coding font */
    font-weight: bold;
  }

  .subTextWrapper {
    height: 80%;
  }
  .subHeading {
    font-size: 2rem;
    margin-bottom: 10px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  /* Coding font */
    color: #e2e2e2; /* Green accent color for the typing effect line */
  }
  
  .subSubHeading {
    font-size: 1.5rem;
    margin-bottom: 10px;
    font-family: 'Courier New', monospace; /* Coding font */
    color: #c8c8c8; /* Green accent color for the typing effect line */
  }

  .typing-cursor {
    display: inline-block;
    width: 2px;
    height: 1.5rem; /* Adjusted cursor height */
    background-color: #ffffff; /* Cursor color */
    animation: blink 0.7s step-end infinite; /* Cursor blink animation */
  }
  

  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
  
  .lastMessage {
    font-family:Arial, Helvetica, sans-serif;
    margin-top: 10vh;
    font-size: 16px;
  }

  @keyframes fadeInMove {
    0% {
      opacity: 0;
      transform: translateY(50px);  /* Start off the screen */
    }
    100% {
      opacity: 1;
      transform: translateY(0);  /* End at normal position */
    }
  }
  
  /* Initial styles */
  .lastMessage {
    opacity: 0;  /* Initially hidden */
    animation: fadeInMove 2s ease-out forwards;  /* Animate on page load */
  }

/* When visible */
.animate-on-scroll.visible {
  opacity: 1;  /* Fade in */
  transform: translateY(0);  /* Move into its normal position */
}
  /* Responsive Design */
  @media (max-width: 768px) {
    .heading {
      font-size: 2rem;
    }
  
    .subHeading {
      font-size: 1.5rem;
    }
  }