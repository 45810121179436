.courses {
    height: 190vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:  rgb(250, 250, 250);
}


.coursesTitleContianer {
    height: 10vh;
}

.coursesTitle {
    font-size: 40px;
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    letter-spacing: 6px;
}



  /* Media query for large devices (min-width: 1200px and max-width: 1449px */
  @media screen and (min-width: 1400px) and (max-width: 1800px) {
    .coursesTitle {
        font-size: 36px;
        letter-spacing: 5px;
    }
  }

    /* Media query for large devices (min-width: 1200px and max-width: 1449px */
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .coursesTitle {
        font-size: 32px;
        letter-spacing: 5px;
    }
}

  /* Media query for large devices (min-width: 992px and max-width: 1199px) */
  @media screen and (min-width: 1000px) and (max-width: 1199px) {
    .coursesTitle {
        font-size: 28px;
        letter-spacing: 4px;
    }   

  }
  
  /* Media query for medium devices (min-width: 768px and max-width: 991px) */
  @media screen and (min-width: 800px) and (max-width: 999px) {
    .coursesTitle {
        font-size: 26px;
        letter-spacing: 4px;
    }   
  }
  
  /* Media query for small devices (min-width: 576px and max-width: 767px) */
  @media screen and (min-width: 650px) and (max-width: 799px) {
    .coursesTitle {
        font-size: 25px;
        letter-spacing: 4px;
    }   
}
  
  /* Media query for extra small devices (max-width: 575px) */
  @media screen and (max-width: 650px) {
    .courses {
        height: 250vh;
        gap: 120px;
    }
    .coursesTitle {
        font-size: 20px;
        letter-spacing: 3px;
    }   
  }


