
@keyframes fadeInMoveSkills {
   0% {
     opacity: 0;
     transform: translateY(50px);  /* Start off the screen */
   }
   100% {
     opacity: 1;
     transform: translateY(0);  /* End at normal position */
   }
}
.skills {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.skillsContainerLeft {
   opacity: 0;  /* Initially hidden */
   animation: fadeInMoveSkills 2s ease-out forwards;
    width: 50%;
    height: 100vh;
}

.skillsContainerLeft h2 {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 0;
}




.skillsContainerRight {
   opacity: 0;  /* Initially hidden */
   animation: fadeInMove 2s ease-out forwards;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8vh ;
    width: 50%;
    background-color: rgb(29, 29, 29);
}

.skillsContainerRightTitle {
   color: white;
   font-size: 60px;
   font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.skillsWrapper {
    margin: 5vh auto;
}


.i-title
{
    height: 300px;
    overflow: hidden;
}

.i-title-wrapper{
    height: 100%;
    animation: move 10s ease-in-out infinite alternate;
}
@keyframes move {
    10% {
       transform: translateY(-100px); 
    }
    20% {
        transform: translateY(-200px); 
     }
     30% {
        transform: translateY(-300px); 
     }
     40% {
        transform: translateY(-400px); 
     }
     50% {
        transform: translateY(-500px); 
     }
     60% {
        transform: translateY(-600px); 
     }
     70% {
        transform: translateY(-700px); 
     }
     80% {
        transform: translateY(-800px); 
     }
     90% {
        transform: translateY(-900px); 
     }
     100% {
        transform: translateY(-1000px); 
     }
}

.i-title-item{
    height: 100px;
    font-size: 99px;
    font-weight: bold;
    color:#e5e5e5;
    display: flex;
    align-items: center;

}




  /* Media query for large devices (min-width: 1200px and max-width: 1449px */
 @media screen and (min-width: 1400px) and (max-width: 1800px) {

  .skillsContainerRight {
      gap: 2vh ;
  }

  .skillsContainerRightTitle {
     font-size: 46px;
  }

  .skillsWrapper {
      margin:  0 auto 1vh;
  }

}

  /* Media query for large devices (min-width: 1200px and max-width: 1449px */
@media screen and (min-width: 1200px) and (max-width: 1399px) {

         .skillsContainerRight {
            gap: 10vh ;
      }

      .skillsContainerRightTitle {
         font-size: 38px;
      }

      .skillsWrapper {
            margin:  0 auto 3vh;
      }

      .i-title
      {
         height: 150px;
      }

      .i-title-wrapper{
         height: 100%;
         animation: move 10s ease-in-out infinite alternate;
      }
      @keyframes move {
         10% {
            transform: translateY(-50px); 
         }
         20% {
            transform: translateY(-100px); 
         }
         30% {
            transform: translateY(-150px); 
         }
         40% {
            transform: translateY(-200px); 
         }
         50% {
            transform: translateY(-250px); 
         }
         60% {
            transform: translateY(-300px); 
         }
         70% {
            transform: translateY(-350px); 
         }
         80% {
            transform: translateY(-400px); 
         }
         90% {
            transform: translateY(-450px); 
         }
         100% {
            transform: translateY(-500px); 
         }
      }
      .i-title-item{
         height: 50px;
         font-size: 50px;
     }
   }


/* Media query for large devices (min-width: 992px and max-width: 1199px) */
@media screen and (min-width: 1000px) and (max-width: 1199px) {


   .skillsContainerRight {
      gap: 8vh ;
}

.skillsContainerRightTitle {
   font-size: 28px;
}

.skillsWrapper {
      margin:  0 auto 2vh;
}

.i-title
{
   height: 150px;
}

.i-title-wrapper{
   height: 100%;
   animation: move 10s ease-in-out infinite alternate;
}
@keyframes move {
   10% {
      transform: translateY(-50px); 
   }
   20% {
      transform: translateY(-100px); 
   }
   30% {
      transform: translateY(-150px); 
   }
   40% {
      transform: translateY(-200px); 
   }
   50% {
      transform: translateY(-250px); 
   }
   60% {
      transform: translateY(-300px); 
   }
   70% {
      transform: translateY(-350px); 
   }
   80% {
      transform: translateY(-400px); 
   }
   90% {
      transform: translateY(-450px); 
   }
   100% {
      transform: translateY(-500px); 
   }
}
.i-title-item{
   height: 50px;
   font-size: 50px;
}
}

/* Media query for medium devices (min-width: 768px and max-width: 991px) */
@media screen and (min-width: 800px) and (max-width: 999px) {

   .skills {
      flex-direction: column;
      height: 200vh;
  }

  .skillsContainerLeft {
      width: 100%;
      height: 100vh;
  }

  .skillsContainerLeft h2 {
      margin-top: 45px;
  }
    .skillsContainerRight {
      height: 100vh;
      gap: 8vh ;
      width: 100%;
      background-color: rgb(29, 29, 29);
  }

.skillsContainerRightTitle {
   font-size: 32px;
}

.skillsWrapper {
      margin:  0 auto 2vh;
}

.i-title
{
   height: 150px;
}

.i-title-wrapper{
   height: 100%;
   animation: move 10s ease-in-out infinite alternate;
}
@keyframes move {
   10% {
      transform: translateY(-50px); 
   }
   20% {
      transform: translateY(-100px); 
   }
   30% {
      transform: translateY(-150px); 
   }
   40% {
      transform: translateY(-200px); 
   }
   50% {
      transform: translateY(-250px); 
   }
   60% {
      transform: translateY(-300px); 
   }
   70% {
      transform: translateY(-350px); 
   }
   80% {
      transform: translateY(-400px); 
   }
   90% {
      transform: translateY(-450px); 
   }
   100% {
      transform: translateY(-500px); 
   }
}
.i-title-item{
   height: 50px;
   font-size: 50px;
}
}

/* Media query for small devices (min-width: 576px and max-width: 767px) */
@media screen and (min-width: 650px) and (max-width: 799px) {
   .skills {
      flex-direction: column;
      height: 200vh;
  }

  .skillsContainerLeft {
      width: 100%;
      height: 100vh;
  }

  .skillsContainerLeft h2 {
      margin-top: 45px;
      text-align: center;
  }
    .skillsContainerRight {
      height: 100vh;
      gap: 8vh ;
      width: 100%;
      background-color: rgb(29, 29, 29);
  }

.skillsContainerRightTitle {
   font-size: 32px;
}

.skillsWrapper {
   width: 70%;
   margin: 4vh 15%;
}

.i-title
{
   height: 150px;
}

.i-title-wrapper{
   height: 100%;
   animation: move 10s ease-in-out infinite alternate;
}
@keyframes move {
   10% {
      transform: translateY(-50px); 
   }
   20% {
      transform: translateY(-100px); 
   }
   30% {
      transform: translateY(-150px); 
   }
   40% {
      transform: translateY(-200px); 
   }
   50% {
      transform: translateY(-250px); 
   }
   60% {
      transform: translateY(-300px); 
   }
   70% {
      transform: translateY(-350px); 
   }
   80% {
      transform: translateY(-400px); 
   }
   90% {
      transform: translateY(-450px); 
   }
   100% {
      transform: translateY(-500px); 
   }
}
.i-title-item{
   height: 50px;
   font-size: 50px;
   text-align: center;
   display: block;
}
}

/* Media query for extra small devices (max-width: 575px) */
@media screen and (max-width: 650px) {
   .skills {
      flex-direction: column;
      height: 200vh;
  }

  .skillsContainerLeft {
      width: 100%;
      height: 100vh;
  }

  .skillsContainerLeft h2 {
      margin-top: 10px;
  }
    .skillsContainerRight {
      height: 100vh;
      width: 100%;
      background-color: rgb(29, 29, 29);
  }

.skillsContainerRightTitle {
   font-size: 30px;
   margin-bottom: 20px;
   text-align: center;
}

.skillsWrapper {
   width: 70%;
   margin: 2vh 15%;
}

.i-title
{
   height: 150px;
}

.i-title-wrapper{
   height: 100%;
   animation: move 10s ease-in-out infinite alternate;
}
@keyframes move {
   10% {
      transform: translateY(-50px); 
   }
   20% {
      transform: translateY(-100px); 
   }
   30% {
      transform: translateY(-150px); 
   }
   40% {
      transform: translateY(-200px); 
   }
   50% {
      transform: translateY(-250px); 
   }
   60% {
      transform: translateY(-300px); 
   }
   70% {
      transform: translateY(-350px); 
   }
   80% {
      transform: translateY(-400px); 
   }
   90% {
      transform: translateY(-450px); 
   }
   100% {
      transform: translateY(-500px); 
   }
}
.i-title-item{
   height: 50px;
   font-size: 50px;
   text-align: center;
   display: block;
}
}