.projects{
   width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: rgb(19, 19, 19);
}

.projects-texts{
    width: 65%;
}

.projects-title{
    font-size: 50px;
    font-weight: 600;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: whitesmoke;
}

.projects-desc{
    margin: 20px 0;
}
.projects-list{
    width: 80vw;
    margin: 0 10vw;
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
}

@media screen and ( max-width: 480px) {
    .projectList{
        padding: 10px;
    }
    .projectList-texts{
        width: 100%;
    }
    
    
}