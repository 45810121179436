.metaCertificate {
    width: 100%;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.metaCertificateContainerLeft {
    height: 90%;
    width: 48%;
    margin: 0 1%;
    border-radius: 12px;
    background-color: rgb(29, 29, 29);
    display: flex;
    align-items: center;
    justify-content: center;

}


.metaCertificatePicture {
    width: 800px;
    border-radius: 10px;
    cursor: pointer;
}

.metaCertificatePicture:hover {
    width: 810px;
}


.metaCertificateContainerRight {
    width: 46%;
    margin: 0 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 18px;
}


.metaLogo {
    width: 150px;
    cursor: pointer;
}


.certificateDescriptionContainerMeta{
    text-align: left;
    width: 90%;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: whitesmoke;
    border-radius: 10px;
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.35);  /* Subtle shadow */
    padding: 28px; 
    font-size: 18px;
}

.certificateDescriptionContainerMeta h3{
    font-size: 26px;
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    letter-spacing: 2px;
    color: rgb(17, 66, 106);
}

.certificateDescriptionContainerMeta ul li {
    margin-bottom: 10px;
}

.certificateDescriptionContainerMeta span {
    font-weight: bold;
}



  /* Media query for large devices (min-width: 1200px and max-width: 1449px */
  @media screen and (min-width: 1400px) and (max-width: 1800px) {
    .metaCertificate {
        width: 100%;
        height: 85vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .metaCertificateContainerLeft {
        height: 90%;
        width: 48%;
        margin: 0 1%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .metaCertificatePicture {
        width: 580px;
    }
    .metaCertificatePicture:hover {
        width: 582px;
    }
    .metaCertificateContainerRight {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 14px;
    }
    .metaLogo {
        width: 130px;
    }
    .certificateDescriptionContainerMeta{
        text-align: left;
        width: 90%;
        padding: 20px; 
        font-size: 14px;
    }
    .certificateDescriptionContainerMeta h3{
        font-size: 20px;
        letter-spacing: 2px;
    }
    .certificateDescriptionContainerMeta ul li {
        margin-bottom: 8px;
    }
  }

    /* Media query for large devices (min-width: 1200px and max-width: 1449px */
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .metaCertificate {
        width: 100%;
        height: 85vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .metaCertificateContainerLeft {
        height: 80%;
        width: 48%;
        margin: 0 1%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .metaCertificatePicture {
        width: 500px;
    }
    .metaCertificatePicture:hover {
        width: 502px;
    }
    .metaCertificateContainerRight {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 12px;
    }
    .metaLogo {
        width: 100px;
    }
    .certificateDescriptionContainerMeta{
        text-align: left;
        width: 90%;
        padding: 20px; 
        font-size: 12px;
    }
    .certificateDescriptionContainerMeta h3{
        font-size: 16px;
        letter-spacing: 2px;
    }
    .certificateDescriptionContainerMeta ul li {
        margin-bottom: 8px;
    }
}
  /* Media query for large devices (min-width: 992px and max-width: 1199px) */
  @media screen and (min-width: 1000px) and (max-width: 1199px) {
    .metaCertificate {
        width: 100%;
        height: 85vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .metaCertificateContainerLeft {
        height: 80%;
        width: 48%;
        margin: 0 1%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .metaCertificatePicture {
        width: 400px;
    }
    .metaCertificatePicture:hover {
        width: 402px;
    }
    .metaCertificateContainerRight {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 12px;
    }
    .metaLogo {
        width: 100px;
    }
    .certificateDescriptionContainerMeta{
        text-align: left;
        width: 90%;
        padding: 20px; 
        font-size: 12px;
    }
    .certificateDescriptionContainerMeta h3{
        font-size: 16px;
        letter-spacing: 2px;
    }
    .certificateDescriptionContainerMeta ul li {
        margin-bottom: 8px;
    }
  }

  /* Media query for medium devices (min-width: 768px and max-width: 991px) */
  @media screen and (min-width: 800px) and (max-width: 999px) {
    .metaCertificate {
        width: 100%;
        height: 85vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .metaCertificateContainerLeft {
        height: 80%;
        width: 48%;
        margin: 0 1%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .metaCertificatePicture {
        width: 370px;
    }
    .metaCertificatePicture:hover {
        width: 372px;
    }
    .metaCertificateContainerRight {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 12px;
    }
    .metaLogo {
        width: 100px;
    }
    .certificateDescriptionContainerMeta{
        text-align: left;
        width: 90%;
        padding: 20px; 
        font-size: 12px;
    }
    .certificateDescriptionContainerMeta h3{
        font-size: 15px;
        letter-spacing: 2px;
    }
    .certificateDescriptionContainerMeta ul li {
        margin-bottom: 8px;
    }
  }

  /* Media query for small devices (min-width: 576px and max-width: 767px) */
  @media screen and (min-width: 650px) and (max-width: 799px) {
    .metaCertificate {
        width: 100%;
        height: 85vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .metaCertificateContainerLeft {
        height: 80%;
        width: 48%;
        margin: 0 1%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .metaCertificatePicture {
        width: 300px;
    }
    .metaCertificatePicture:hover {
        width: 302px;
    }
    .metaCertificateContainerRight {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 10px;
    }
    .metaLogo {
        width: 100px;
    }
    .certificateDescriptionContainerMeta{
        text-align: left;
        width: 90%;
        padding: 20px; 
        font-size: 10px;
    }
    .certificateDescriptionContainerMeta h3{
        font-size: 12px;
        letter-spacing: 2px;
    }
    .certificateDescriptionContainerMeta ul li {
        margin-bottom: 8px;
    }
}

  /* Media query for extra small devices (max-width: 575px) */
  @media screen and (max-width: 650px) {
    .metaCertificate {
        flex-direction: column;
        width: 100%;
        height: 85vh;
        align-items: center;
        justify-content: space-between;
    }
    .metaCertificateContainerLeft {
        height: 50%;
        width: 90vw;
        margin: 0 5vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .metaCertificatePicture {
        margin: 0 10%;
        width: 80%;
    }
    .metaCertificatePicture:hover {
        width: 80%;
    }
    .metaCertificateContainerRight {
        width: 100%;
        height: 30vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 10px;
        margin: 0;
    }
    .metaLogo {
        width: 100px;
    }
    .certificateDescriptionContainerMeta{
        text-align: left;
        width: 90%;
        height: 80%;
        padding: 2px; 
        font-size: 10px;
    }
    .certificateDescriptionContainerMeta h3{
        font-size: 11px;
        letter-spacing: 2px;
    }
    .certificateDescriptionContainerMeta ul li {
        margin-bottom: 8px;
    }
  }
