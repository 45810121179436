/* Correct import */
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

/* Carousel container */
.carousel-container {
    width: 80%; /* Adjust this based on your design */
    margin: 0 auto;
    padding: 50px 0;
  }
  
  /* Individual carousel item */
  .carousel-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    background-color: #f4f4f4; /* Light background color */
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
  
  /* Title of each carousel item */
  .carousel-item-title {
    font-size: 2rem;
    color: #333; /* Dark color for the title */
    margin-bottom: 15px;
  }

  /* Text content of each carousel item */
  .carousel-item-text {
    font-size: 1.2rem;
    color: #555; /* Slightly lighter color for the text */
    margin-bottom: 20px;
  }

  @media screen and (max-width: 650px) {
    .carousel-container {

      padding: 2px 0;
    }
    
    /* Individual carousel item */
    .carousel-item {
     
      padding: 15px;
    }
    
    /* Title of each carousel item */
    .carousel-item-title {
      font-size: 1.5rem;

    }
  
    /* Text content of each carousel item */
    .carousel-item-text {
      font-size: 1rem;
    }
 }