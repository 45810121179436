@keyframes fadeInMove {
    0% {
      opacity: 0;
      transform: translateY(50px);  /* Start off the screen */
    }
    100% {
      opacity: 1;
      transform: translateY(0);  /* End at normal position */
    }
}
.heroSection {
   width: 100%;
   height: 100vh;
    background-color: rgb(240, 240, 240);
}
.heroSectionContainer {
    height: 100vh;
    width: 90%;
    margin: 0 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.heroSectionContainerLeft {
    opacity: 0;  /* Initially hidden */
    animation: fadeInMove 2s ease-out forwards;
    flex: 6;
    width: 96%;
    margin: 0 2%;
}

.heroSectionContainerLeft h1 {
    font-family: 'Cormorant Garamond', serif;
    font-size: 60px;
    font-weight: bold;
}

.heroSectionContainerLeft h2 {
    font-family: 'Exo 2', sans-serif;
    font-weight: bold;
    letter-spacing: 5px;
    font-size: 32px;
    font-weight: bold;
    color: #6e0d5f;
}
.heroSectionContainerLeft p {
    font-family: 'Exo 2', sans-serif;
    font-size: 18px;
}
.heroSectionContainerLeft h3 {
    margin-bottom: 40px;
}
.buttonToContactMe {
    outline: none;
    text-decoration: none; /* Removes the underline */
    border: 2px solid black;
    padding: 15px 25px;
    font-size: 24px;
    border-radius: 8px;
    background-color: black;
    color: whitesmoke;
    cursor: pointer;
    font-weight: bold;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.buttonToContactMe:hover {
    background-color: whitesmoke;
    color: black;
}


.heroSectionContainerRigt {
    opacity: 0;  /* Initially hidden */
    animation: fadeInMove 1s ease-out forwards;
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}


.profilePicture {
    width: 600px;
    height: 350px;
    object-fit: cover;
    border-radius: 10px;
}

.heroSectionIconSocialMedia {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.socialIcon {
    color: rgb(0, 0, 0); /* Or any color you want */
    font-size: 3.5rem; /* Adjust the size */
    transition: color 0.3s ease;
  }

  .socialIcon:hover {
    color: #6e0d5f; /* Accent color on hover */
  }



  /* Media query for large devices (min-width: 1200px and max-width: 1449px */
  @media screen and (min-width: 1400px) and (max-width: 1800px) {

     .heroSectionContainerLeft h1 {
         font-size: 48px;
     }
     .heroSectionContainerLeft h2 {
        letter-spacing: 4px;
         font-size: 25px;
     }
     .heroSectionContainerLeft p {
         font-size: 16px;
     }
     .heroSectionContainerLeft h3 {
         margin-bottom: 30px;
     }
     .buttonToContactMe {
         padding: 10px 20px;
         font-size: 20px;
     }

     .profilePicture {
         width: 460px;
         height: 260px;
     }

     .socialIcon {
         color: rgb(0, 0, 0); /* Or any color you want */
         font-size: 3rem; /* Adjust the size */
       }
  }

    /* Media query for large devices (min-width: 1200px and max-width: 1449px */
    @media screen and (min-width: 1200px) and (max-width: 1399px) {

        .heroSectionContainerLeft h1 {
            font-size: 44px;
        }
        .heroSectionContainerLeft h2 {
            letter-spacing: 3px;
            font-size: 22px;
            margin-bottom: 15px;
        }
        .heroSectionContainerLeft p {
            font-size: 15px;
        }
        .heroSectionContainerLeft h3 {
            margin-bottom: 26px;
            font-size: 15px;
        }
        .buttonToContactMe {
            padding: 10px 20px;
            font-size: 20px;
        }
        .profilePicture {
            width: 410px;
            height: 210px;
        }

        .socialIcon {
            color: rgb(0, 0, 0); /* Or any color you want */
            font-size: 2.5rem; /* Adjust the size */
          }
     }


  /* Media query for large devices (min-width: 992px and max-width: 1199px) */
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    /* Styles for screens between 992px and 1199px */
    
        .heroSectionContainerLeft h1 {
            font-size: 38px;
        }
        .heroSectionContainerLeft h2 {
            letter-spacing: 2.2px;
            font-size: 17px;
            margin-bottom: 15px;
        }
        .heroSectionContainerLeft p {
            font-size: 13px;
        }
        .heroSectionContainerLeft h3 {
            margin-bottom: 18px;
            font-size: 14px;
        }
        .buttonToContactMe {
            padding: 6px 16px;
            font-size: 16px;
        }
        .profilePicture {
            width: 370px;
            height: 180px;
        }

        .socialIcon {
            color: rgb(0, 0, 0); /* Or any color you want */
            font-size: 2rem; /* Adjust the size */
        }
  }
  
  /* Media query for medium devices (min-width: 768px and max-width: 991px) */
  @media screen and (min-width: 800px) and (max-width: 991px) {
    
    .heroSectionContainerLeft h1 {
        font-size: 30px;
    }
    .heroSectionContainerLeft h2 {
        letter-spacing: 2px;
        font-size: 14px;
        margin-bottom: 15px;
    }
    .heroSectionContainerLeft p {
        font-size: 11px;
    }
    .heroSectionContainerLeft h3 {
        margin-bottom: 16px;
        font-size: 11px;
    }
    .buttonToContactMe {
        padding: 5px 12px;
        font-size: 12px;
    }
    .profilePicture {
        width: 290px;
        height: 140px;
    }

    .socialIcon {
        color: rgb(0, 0, 0); /* Or any color you want */
        font-size: 1.5rem; /* Adjust the size */
    }
  }
  
  /* Media query for small devices (min-width: 576px and max-width: 767px) */
  @media screen and (min-width: 650px) and (max-width: 799px) {
    /* Styles for screens between 576px and 767px */
    .heroSectionContainerLeft h1 {
        font-size: 22px;
    }
    .heroSectionContainerLeft h2 {
        letter-spacing: 1.1px;
        font-size: 13px;
        margin-bottom: 13px;
    }
    .heroSectionContainerLeft p {
        font-size: 10px;
    }
    .heroSectionContainerLeft h3 {
        margin-bottom: 13px;
        font-size: 10px;
    }
    .buttonToContactMe {
        padding: 4px 8px;
        font-size: 10px;
    }
    .profilePicture {
        width: 230px;
        height: 110px;
    }

    .socialIcon {
        color: rgb(0, 0, 0); /* Or any color you want */
        font-size: 1rem; /* Adjust the size */
    }
  }
  
  /* Media query for extra small devices (max-width: 575px) */
  @media screen and (max-width: 650px) {

    .heroSection {
        width: 100%;
        height: 100vh;
         background-color: rgb(240, 240, 240);
     }
    .heroSectionContainer {
        flex-direction: column;
        gap: 70px;
    }
    .heroSectionContainerLeft {
        margin-top: 20px;
        height: 70%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 8px;
    }
    .heroSectionContainerRight {
        height: 30%;
        flex: 1;
        align-items: flex-start;
        justify-content: center;
    }

    .heroSectionContainerRigt {
        justify-content: center;
    }    

     .heroSectionContainerLeft h1 {
        font-size: 30px;
    }
    .heroSectionContainerLeft h2 {
        letter-spacing: 2px;
        font-size: 15px;
        margin-bottom: 13px;
    }
    .heroSectionContainerLeft p {
        font-size: 16px;
    }
    .heroSectionContainerLeft h3 {
        margin-bottom: 13px;
        font-size: 13px;
    }
    .buttonToContactMe {
        padding: 4px 8px;
        font-size: 13px;
        text-align: center;
    }
    .profilePicture {
        width: 60vw;
        height: 15vh;
        margin-bottom: 10px;
    }

    .socialIcon {
        color: rgb(0, 0, 0); /* Or any color you want */
        font-size: 2.5rem; /* Adjust the size */
    }
  }