.harvardCertificate {
    width: 100%;
    height: 85vh;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}

.harvardLogo {
    width: 180px;
    margin-bottom: 50px;
}

.certificateDescriptionContainerHarvard{
    text-align: left;
    width: 90%;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: whitesmoke;
    border-radius: 10px;
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.35);  /* Subtle shadow */
    padding: 30px; 
    font-size: 18px;
}

.certificateDescriptionContainerHarvard h3{
    font-size: 26px;
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    letter-spacing: 2px;
    color: rgb(77, 23, 23);
}

.certificateDescriptionContainerHarvard ul li {
    margin-bottom: 10px;
}

.certificateDescriptionContainerHarvard span {
    font-weight: bold;
}



 /* Media query for large devices (min-width: 1200px and max-width: 1449px */
 @media screen and (min-width: 1400px) and (max-width: 1800px) {
    .harvardCertificate {
        width: 100%;
        height: 85vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .metaCertificateContainerLeft {
        height: 90%;
        width: 48%;
        margin: 0 1%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .metaCertificatePicture {
        width: 600px;
    }
    .metaCertificatePicture:hover {
        width: 602px;
    }

    .metaCertificateContainerRight {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 14px;
    }

    .harvardLogo {
        width: 140px;
    }
    .certificateDescriptionContainerHarvard{
        text-align: left;
        width: 90%;
        padding: 20px; 
        font-size: 14px;
    }
    .certificateDescriptionContainerHarvard h3{
        font-size: 20px;
        letter-spacing: 2px;
    }
    .certificateDescriptionContainerHarvard ul li {
        margin-bottom: 8px;
    }
  }

    /* Media query for large devices (min-width: 1200px and max-width: 1449px */
  @media screen and (min-width: 1200px) and (max-width: 1399px) {

    .harvardCertificate {
        width: 100%;
        height: 85vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .metaCertificateContainerLeft {
        height: 90%;
        width: 48%;
        margin: 0 1%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .metaCertificatePicture {
        width: 450px;
    }
    .metaCertificatePicture:hover {
        width: 452px;
    }

    .metaCertificateContainerRight {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 12px;
    }

    .harvardLogo {
        width: 120px;
    }
    .certificateDescriptionContainerHarvard{
        text-align: left;
        width: 90%;
        padding: 20px; 
        font-size: 12px;
    }
    .certificateDescriptionContainerHarvard h3{
        font-size: 16px;
        letter-spacing: 2px;
    }
    .certificateDescriptionContainerHarvard ul li {
        margin-bottom: 8px;
    }
}

    /* Media query for large devices (min-width: 992px and max-width: 1199px) */
    @media screen and (min-width: 1000px) and (max-width: 1199px) {
        .harvardCertificate {
            width: 100%;
            height: 85vh;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .metaCertificateContainerLeft {
            height: 90%;
            width: 48%;
            margin: 0 1%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .metaCertificatePicture {
            width: 380px;
        }
        .metaCertificatePicture:hover {
            width: 382px;
        }
        .metaCertificateContainerRight {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            font-size: 12px;
        }
        .harvardLogo {
            width: 100px;
        }
        .certificateDescriptionContainerHarvard{
            text-align: left;
            width: 90%;
            padding: 16px; 
            font-size: 12px;
        }
        .certificateDescriptionContainerHarvard h3{
            font-size: 14px;
            letter-spacing: 2px;
        }
        .certificateDescriptionContainerHarvard ul li {
            margin-bottom: 8px;
        }
    }
  
    /* Media query for medium devices (min-width: 768px and max-width: 991px) */
    @media screen and (min-width: 800px) and (max-width: 999px) {
        .harvardCertificate {
            width: 100%;
            height: 85vh;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .metaCertificateContainerLeft {
            height: 90%;
            width: 48%;
            margin: 0 1%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .metaCertificatePicture {
            width: 380px;
        }
        .metaCertificatePicture:hover {
            width: 382px;
        }
        .metaCertificateContainerRight {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            font-size: 12px;
        }
        .harvardLogo {
            width: 100px;
        }
        .certificateDescriptionContainerHarvard{
            text-align: left;
            width: 90%;
            padding: 16px; 
            font-size: 12px;
        }
        .certificateDescriptionContainerHarvard h3{
            font-size: 14px;
            letter-spacing: 2px;
        }
        .certificateDescriptionContainerHarvard ul li {
            margin-bottom: 8px;
        }
    }
  
    /* Media query for small devices (min-width: 576px and max-width: 767px) */
    @media screen and (min-width: 650px) and (max-width: 799px) {
        .harvardCertificate {
            width: 100%;
            height: 85vh;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .metaCertificateContainerLeft {
            height: 90%;
            width: 48%;
            margin: 0 1%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .metaCertificatePicture {
            width: 380px;
        }
        .metaCertificatePicture:hover {
            width: 382px;
        }
        .metaCertificateContainerRight {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            font-size: 10px;
        }
        .harvardLogo {
            width: 100px;
        }
        .certificateDescriptionContainerHarvard{
            text-align: left;
            width: 90%;
            padding: 16px; 
            font-size: 10px;
        }
        .certificateDescriptionContainerHarvard h3{
            font-size: 12px;
            letter-spacing: 2px;
        }
        .certificateDescriptionContainerHarvard ul li {
            margin-bottom: 8px;
        }
  }

    /* Media query for extra small devices (max-width: 575px) */
    @media screen and (max-width: 650px) {
        .harvardCertificate {
            flex-direction: column;
            width: 100%;
            height: 85vh;
            align-items: center;
            justify-content: space-between;
        }
        .metaCertificateContainerLeft {
            height: 50%;
            width: 90vw;
            margin: 0 5vw;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .metaCertificatePicture {
            margin: 0 10%;
            width: 80%;
        }
        .metaCertificatePicture:hover {
            width: 80%;
        }
        .metaCertificateContainerRight {
            width: 100%;
            height: 50vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            font-size: 10px;
            margin: 0;
        }
        .harvardLogo {
            margin-bottom: 20px;
            margin-top: 20px;
            width: 100px;
        }
        .certificateDescriptionContainerHarvard{
            text-align: left;
            width: 90%;
            height: 80%;
            padding: 4px; 
            font-size: 10px;
        }
        .certificateDescriptionContainerHarvard h3{
            font-size: 11px;
            letter-spacing: 2px;
        }
        .certificateDescriptionContainerHarvard ul li {
            margin-bottom: 8px;
        }
    }
