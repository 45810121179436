
@keyframes fadeInMoveMathSkills {
    0% {
      opacity: 0;
      transform: translateY(50px);  /* Start off the screen */
    }
    100% {
      opacity: 1;
      transform: translateY(0);  /* End at normal position */
    }
  }

.mathSkill {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mathSkillLeft {
    opacity: 0;  /* Initially hidden */
    animation: fadeInMoveMathSkills 2s ease-out forwards;
    background-color: rgb(29, 29, 29);
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: 120px;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}

.mathSkillTitleLittle {
    font-size: 100px;
}

.mathSkillTitleBig {
    font-size: 150px;
}



.mathSkillRight {
    opacity: 0;  /* Initially hidden */
    animation: fadeInMoveMathSkills 2s ease-out forwards;
    width: 50%;
    height: 98%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.mathSkillDescription {
    width: 90%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    text-align: center;
}

.mathSkillDescription h3 {
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 70px;
    color: rgb(26, 29, 35);
}

.mathSkillDescription p {
    font-size: 20px;
}

.i-title-math
{
    height: 150px;
    overflow: hidden;
}

.i-title-wrapper-math{
    height: 100%;
    animation: moveMath 10s ease-in-out infinite alternate;
}
@keyframes moveMath {
    10% {
       transform: translateY(-50px); 
    }
    20% {
        transform: translateY(-100px); 
     }
     30% {
        transform: translateY(-150px); 
     }
     40% {
        transform: translateY(-200px); 
     }
     50% {
        transform: translateY(-250px); 
     }
     60% {
        transform: translateY(-300px); 
     }
     70% {
        transform: translateY(-350px); 
     }
     80% {
        transform: translateY(-400px); 
     }
     90% {
        transform: translateY(-450px); 
     }
     100% {
        transform: translateY(-500px); 
     }
}

.i-title-item-math{
    height: 50px;
    font-size: 50px;
    font-weight: bold;
    color:rgb(20, 20, 20);
    text-align: center;

}



 /* Media query for large devices (min-width: 1200px and max-width: 1449px */
 @media screen and (min-width: 1400px) and (max-width: 1800px) {
    .mathSkillLeft {
        font-size: 90px;
    }
    
    .mathSkillTitleLittle {
        font-size: 70px;
    }
    
    .mathSkillTitleBig {
        font-size: 115px;
    }

    .mathSkillDescription {
        gap: 40px;
    }

    .mathSkillDescription h3 {
        font-size: 50px;
        margin-bottom: 0;
    }

    .mathSkillDescription p {
        font-size: 19px;
    }
    
}
  /* Media query for large devices (min-width: 992px and max-width: 1199px) */
  @media screen and (min-width: 1000px) and (max-width: 1399px) {
    .mathSkillLeft {
        font-size: 75px;
    }
    
    .mathSkillTitleLittle {
        font-size: 60px;
    }
    
    .mathSkillTitleBig {
        font-size: 80px;
    }

    .mathSkillDescription {
        gap: 32px;
    }

    .mathSkillDescription h3 {
        font-size: 44px;
        margin-bottom: 0;
    }

    .mathSkillDescription p {
        font-size: 18px;
    }
  }
  
  /* Media query for medium devices (min-width: 768px and max-width: 991px) */
  @media screen and (min-width: 750px) and (max-width: 999px) {
    .mathSkillLeft {
        font-size: 60px;
    }
    
    .mathSkillTitleLittle {
        font-size: 40px;
    }
    
    .mathSkillTitleBig {
        font-size: 62px;
    }

    .mathSkillDescription {
        gap: 33px;
    }

    .mathSkillDescription h3 {
        font-size: 34px;
        margin-bottom: 0;
    }

    .mathSkillDescription p {
        font-size: 16px;
    }

.i-title-math
{
    height: 75px;
    overflow: hidden;
}

.i-title-wrapper-math{
    height: 100%;
    animation: moveMath 10s ease-in-out infinite alternate;
}
@keyframes moveMath {
    10% {
       transform: translateY(-25px); 
    }
    20% {
        transform: translateY(-50px); 
     }
     30% {
        transform: translateY(-75px); 
     }
     40% {
        transform: translateY(-100px); 
     }
     50% {
        transform: translateY(-125px); 
     }
     60% {
        transform: translateY(-150px); 
     }
     70% {
        transform: translateY(-175px); 
     }
     80% {
        transform: translateY(-200px); 
     }
     90% {
        transform: translateY(-225px); 
     }
     100% {
        transform: translateY(-250px); 
     }
}

.i-title-item-math{
    height: 25px;
    font-size: 25px;
}
  }
  
  /* Media query for extra small devices (max-width: 575px) */
  @media screen and (max-width: 750px) {
    .mathSkill {
        height: 200vh;
        flex-direction: column;
        justify-content: center;
    }

    .mathSkillLeft {
        background-color: rgb(29, 29, 29);
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        font-size: 55px;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
    }

    .mathSkillTitleLittle {
        font-size: 50px;
    }

    .mathSkillTitleBig {
        font-size: 55px;
    }



    .mathSkillRight {
        width: 100%;
        height: 100vh;
        justify-content: center;
        gap: 120px;
    }

    .mathSkillDescription {
        width: 90%;
        height: 50%;
        gap: 0;
    }

    .mathSkillDescription h3 {
        font-size: 55px;
    }

    .mathSkillDescription p {
        font-size: 16px;
    }

    .i-title-math
    {
        height: 75px;
        overflow: hidden;
    }

    .i-title-wrapper-math{
        height: 100%;
        animation: moveMath 10s ease-in-out infinite alternate;
    }
    @keyframes moveMath {
        10% {
        transform: translateY(-25px); 
        }
        20% {
            transform: translateY(-50px); 
        }
        30% {
            transform: translateY(-75px); 
        }
        40% {
            transform: translateY(-100px); 
        }
        50% {
            transform: translateY(-125px); 
        }
        60% {
            transform: translateY(-150px); 
        }
        70% {
            transform: translateY(-175px); 
        }
        80% {
            transform: translateY(-200px); 
        }
        90% {
            transform: translateY(-225px); 
        }
        100% {
            transform: translateY(-250px); 
        }
    }

    .i-title-item-math{
        height: 25px;
        font-size: 25px;
    }

  }