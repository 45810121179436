.c{
    height: 100vh;
    position: relative;
    background-color: rgb(245, 245, 245);
}
.c-bg{
    width: 20px;
    height: 100%;
    background-color: rgb(20, 20, 20);
    position: absolute;
}

.c-wrapper{
    padding: 50px;
    display: flex;
}

.c-left{
    flex: 1;
}

.c-right{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.c-title{
    font-size: 60px;
    width: 80%;
}
.c-info-item{
    display: flex;
    align-items: center;
    margin: 50px 0px;
    font-weight: 300;
    width: 70%;
}
.c-icon{
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

.c-desc{
    font-weight: 200;
}

form{
    margin-top: 20px;
}

input{
    width:50%;
    height: 50px;
    border: none;
    border-bottom: 1px solid black ;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
    background-color: rgb(245, 245, 245);
}
input:focus {
    outline: none; /* Remove default outline */
    border: 1px solid rgb(20, 20, 20);
 
}
textarea{
    width: 100%;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
    background-color: rgb(245, 245, 245);
}

textarea:focus {
    outline: none; /* Remove default outline */
    border: 1px solid rgba(32, 30, 30, 0.977);
}

button{
    border: none;
    padding: 15px;
    background-color: rgb(20, 20, 20);
    border: 1px solid rgb(20, 20, 20);
    font-weight: 500;
    color: white;
    cursor: pointer;
    font-weight: bold;
}
button:hover {
    background-color: white;
    color: rgb(20, 20, 20);
    border-color: rgb(20, 20, 20);
}
.c-thank{

    letter-spacing: 7px;
    font-weight: bold;
}

@media screen and ( max-width: 480px) {
    .c-wrapper{
        flex-direction: column;
        padding: 0px 50px;
    }

    .c-title{
        font-size: 30px;
    }

    .c-info-item{
        margin: 20px 0px;
        width: 100%;
    }

    .c-desc{
        display: none;
    }

    .form{
        margin-top: 0px;
        display: flex;
        flex-wrap: wrap;
    }
    input{
        width: 35%;
        height: 40px;
        margin: 10px;
        margin-left: 0;
    }
    
    button{
        margin-top: 10px;
        
    }
}