.proteinsVideoList {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.gif-video {
    width: 100px;
}



/* Media query for small devices (min-width: 576px and max-width: 767px) */
@media screen and (min-width: 650px) and (max-width: 799px) {
.gif-video {
    width: 60px;
}
}

/* Media query for extra small devices (max-width: 575px) */
@media screen and (max-width: 650px) {
    .gif-video {
        width: 40px;
    }
}