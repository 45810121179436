.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000; /* Pure black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
    font-family: 'Courier New', Courier, monospace;
  }
  
  /* Binary background styles */
  .binary-background {
    position: absolute;
    top: 0;
    left: 2%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    pointer-events: none; /* Ensure clicks pass through */
    opacity: 0.3; /* Transparent binary effect */
  }
  
  .binary-column {
    flex: 1 0 5%; /* Adjusted width for responsiveness */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    animation: scrollBinary 5s linear infinite;
  }
  
  .binary-digit {
    color: #fff;
    font-size: clamp(24px, 5vw, 36px); /* Bigger, responsive binary digits */
    opacity: 0.9;
  }
  
  /* Scroll binary numbers up */
  @keyframes scrollBinary {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  
  /* Wrapper for loading elements */
  .loading-elements {
    text-align: center;
    color: #fff; /* White text */
    position: relative;
    z-index: 10; /* Make sure it appears above the binary background */
  }
  
  /* Loading text styling */
  .loading-text h2 {
    font-size: clamp(20px, 6vw, 40px);  /* Responsive font size */
    color: #fff; /* White color */
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 20px;
    letter-spacing: 0.15em; /* Spaced out for minimal style * /* Removed any blue light/glow effect */
  }
  
  /* Flickering dots */
  .dots {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 40px;
  }
  
  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.3;
    animation: flicker 1.5s infinite ease-in-out;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  /* Progress bar container */
  .progress-bar-container {
    width: 80%;
    height: 6px;
    background-color: rgba(255, 255, 255, 0.1);
    margin: 0 auto;
    border-radius: 3px;
    overflow: hidden;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #fff; /* White bar */
    transition: width 0.3s ease;
  }
  
  /* Flicker animation for the dots */
  @keyframes flicker {
    0%, 100% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
  }
  
  /* Responsive behavior for mobile */
  @media (max-width: 768px) {
    .loading-elements {
      width: 90%;
    }
  }
  
  @media (max-width: 480px) {
    .loading-text h2 {
      font-size: 22px;
    }
  
    .binary-digit {
      font-size: 24px;
    }
  }
  