.lmuSection {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(240, 240, 240);

}

.lmuSectionContainer {
    width: 90%;
    margin: 0 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.lmuContainerLeft {
    width: 50%;
    height: 80vh;
    margin-left: 10px;
}

.lmuContainerLeft h2 {
    font-size: 36px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    margin-bottom: 10vh;
}

.lmuContainerRight {
    width: 50%;
}

.lmuLogo {
    width: 300px;
    margin-bottom: 10px;
}

.rankingContainer {
    width: 700px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.rankingWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.rankingWrapper span {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.worldRanking {
    width: 80px;
    height: 80px;
    border: 2px solid #005f36;
    color: #005f36;
    font-weight: bold;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
}

.germanyRanking {
    width: 80px;
    height: 80px;
    border: 2px solid #005f36;
    color: #005f36;
    font-weight: bold;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
}




.lmuBuildingPictureWrapper {
    position: relative;
    width: 100%;

}

.lmuBuildingPictureContainer {
    width: 700px;
    height: 340px;
    border-radius: 10px;
    background-color: rgb(44, 44, 44);
    position: absolute;
    z-index: 0;
}
.lmuBuildingPicture {
    width: 700px;
    border-radius: 10px;
    z-index: 10;
    position: absolute;
    top: 30px;
    left: 30px;
}

.lmuContainerRight article {
    font-size: 22px;
}






  /* Media query for large devices (min-width: 1200px and max-width: 1449px */
  @media screen and (min-width: 1400px) and (max-width: 1800px) {
    .lmuContainerLeft h2 {
        font-size: 30px;
        margin-bottom: 15px;
    }

    .lmuContainerRight {
        width: 50%;
    }

    .lmuLogo {
        width: 200px;

    }

    .rankingContainer {
        width: 680px;
    }

    .worldRanking {
        font-size: 28px;
    }

    .germanyRanking {
        font-size: 26px;
    }

    .lmuBuildingPictureContainer {
        width: 580px;
        height: 280px;
    }
    .lmuBuildingPicture {
        width: 580px;

    }

    .lmuContainerRight article {
        font-size: 18px;
    }
  }

    /* Media query for large devices (min-width: 1200px and max-width: 1449px */
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .lmuContainerLeft h2 {
        font-size: 28px;
        margin-bottom: 15px;
    }


    .lmuLogo {
        width: 150px;

    }

   
.rankingContainer {
    width: 480px;
}

.worldRanking {
    width: 60px;
    height: 60px;
    font-size: 24px;
}

.germanyRanking {
    width: 60px;
    height: 60px;
    font-size: 30px;
}

    .lmuBuildingPictureContainer {
        width: 490px;
        height: 220px;
    }
    .lmuBuildingPicture {
        width: 490px;

    }

    .lmuContainerRight article {
        font-size: 17px;
    }

     }
  
  
  /* Media query for large devices (min-width: 992px and max-width: 1199px) */
  @media screen and (min-width: 1000px) and (max-width: 1199px) {
    .lmuContainerLeft h2 {
        font-size: 26px;
        margin-bottom: 80px;
    }


    .lmuLogo {
        width: 130px;

    }

.rankingContainer {
    width: 420px;
}

.worldRanking {
    width: 50px;
    height: 50px;
    font-size: 22px;
}

.germanyRanking {
    width: 50px;
    height: 50px;
    font-size: 22px;
}

    .lmuBuildingPictureContainer {
        width: 410px;
        height: 170px;
    }
    .lmuBuildingPicture {
        width: 410px;

    }

    .lmuContainerRight article {
        font-size: 16px;
    }
  }
  
  /* Media query for medium devices (min-width: 768px and max-width: 991px) */
  @media screen and (min-width: 800px) and (max-width: 999px) {
    .lmuContainerLeft h2 {
        font-size: 25px;
        margin-bottom: 80px;
    }


    .lmuLogo {
        width: 100px;

    }

.rankingContainer {
    width: 380px;
}

.worldRanking {
    width: 45px;
    height: 45px;
    font-size: 22px;
}

.germanyRanking {
    width: 45px;
    height: 45px;
    font-size: 22px;
}

    .lmuBuildingPictureContainer {
        width: 320px;
        height: 160px;
    }
    .lmuBuildingPicture {
        width: 320px;

    }

    .lmuContainerRight article {
        font-size: 13px;
    }
  }
  
  /* Media query for small devices (min-width: 576px and max-width: 767px) */
  @media screen and (min-width: 650px) and (max-width: 799px) {
    .lmuContainerLeft h2 {
        font-size: 22px;
        margin-bottom: 50px;
    }


    .lmuLogo {
        width: 80px;

    }

.rankingContainer {
    width: 320px;
}

.worldRanking {
    width: 40px;
    height: 40px;
    font-size: 20px;
}

.germanyRanking {
    width: 40px;
    height: 40px;
    font-size: 20px;
}

    .lmuBuildingPictureContainer {
        width: 230px;
        height: 110px;
    }
    .lmuBuildingPicture {
        width: 230px;

    }

    .lmuContainerRight article {
        font-size: 12px;
    }
  }
  
  /* Media query for extra small devices (max-width: 575px) */
  @media screen and (max-width: 650px) {

    .lmuSection {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: rgb(240, 240, 240);
    
    }
    
    .lmuSectionContainer {
        height: 100vh;
        width: 95%;
        flex-direction: column;
    }

    .lmuContainerLeft {
        width: 100%;
        height: 50vh;
    }
    .lmuContainerRight {
        width: 100%;
        height: 50vh;
    }
    .lmuBuildingPictureWrapper {
        width: 100%;
        position: static;
    }
    
    .lmuBuildingPictureContainer {
        display: hidden;
        position: static;
        display: none;
    }
    .lmuBuildingPicture {
        width: 100%;
        border-radius: 10px;
        z-index: 10;
        position: static;
    }

    .lmuContainerLeft h2 {
        font-size: 22px;
        margin-bottom: 50px;
        text-align: center;
    }


    .lmuLogo {
        width: 40%;
        margin: 0 30%;

    }

.rankingContainer {
    width: 320px;
}

.worldRanking {
    width: 40px;
    height: 40px;
    font-size: 20px;
}

.germanyRanking {
    width: 40px;
    height: 40px;
    font-size: 20px;
}

    .lmuBuildingPictureContainer {
        width: 230px;
        height: 110px;
    }
    .lmuBuildingPicture {
        width: 230px;

    }

    .lmuContainerRight article {
        font-size: 12px;
    }
  }