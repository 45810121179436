/* Navbar styles */
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000000;
  display: flex;
  justify-content: center;
  z-index: 1000;
  transition: opacity 0.3s ease-in-out;
}

nav {
    width: 100%;
    height: 5vh;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #19191997;
}



.navbarListElementLink {
    outline: none;
    text-decoration: none; /* Removes the underline */
    color: rgb(223, 223, 223);
    font-family: monospace;
    font-size: 16px;
    cursor: pointer;
}
.navbarListElementLink:hover {
    color: rgb(255, 255, 255);
    font-weight: bold;
}




  header nav {
    opacity: 1; /* Navbar visible after scrolling */
  }

  header.navbar.hidden {
    opacity: 0; /* Navbar is hidden before scrolling */
  }

  nav ul {
    width: 80%;
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: space-around;
    margin: 0;
    padding: 0;
  }

  nav ul  li {
    margin: 0;
  }


/* Media query for extra large devices (min-width: 1200px) */
@media screen and (min-width: 1450px) {
  /* Styles for screens wider than 1200px */
}

/* Media query for large devices (min-width: 1200px and max-width: 1449px */
@media screen and (min-width: 1200px) and (max-width: 1449px) {
  /* Styles for screens between 992px and 1199px */
}

/* Media query for large devices (min-width: 992px and max-width: 1199px) */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  /* Styles for screens between 992px and 1199px */
  .navbarListElementLink {
    font-size: 13px;
  }
}

/* Media query for medium devices (min-width: 768px and max-width: 991px) */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .navbarListElementLink {
    font-size: 12px;
  }
}

/* Media query for small devices (min-width: 576px and max-width: 767px) */
@media screen and (min-width: 650px) and (max-width: 767px) {
  /* Styles for screens between 576px and 767px */
  .navbarListElementLink {
    font-size: 11px;
  }
}

/* Media query for extra small devices (max-width: 575px) */
@media screen and (max-width: 650px) {
  /* Styles for screens smaller than 575px */
  .navbarListElementLink {
    font-size: 13px;
  }
}