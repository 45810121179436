footer {
    width: 100vw;
    height: 25vh;
    background-color: rgb(15, 15, 15);
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.footerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 0 10%;
}


.footerLeft {
    width: 50%;
}
.signatur{
    color: white;
    display: flex;flex-direction: column;
    
}
.footerRight {
    width: 50%;
}

.footerIconSocialMedia {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.footerSocialIcon {
    color: white;
    font-size: 40px;
}

.footerLastQoute {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 5px;
    align-items: center;
    color: white;
    font-family: 'Times New Roman', Times, serif;
}



  /* Media query for large devices (min-width: 1200px and max-width: 1449px */
  @media screen and (min-width: 1400px) and (max-width: 1800px) {

  
  }
  
    /* Media query for large devices (min-width: 1200px and max-width: 1449px */
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
  
}
  
  
  /* Media query for large devices (min-width: 992px and max-width: 1199px) */
  @media screen and (min-width: 1000px) and (max-width: 1199px) {

  }
  
  /* Media query for medium devices (min-width: 768px and max-width: 991px) */
  @media screen and (min-width: 800px) and (max-width: 999px) {
    footer {
        gap: 18px;
    }

    .footerRight {
        width: 50%;
    }

 .footerSocialIcon {
        color: white;
        font-size: 36px;
    }
    .footerLastQoute {
        gap: 4px;
    }
  }
  
  /* Media query for small devices (min-width: 576px and max-width: 767px) */
  @media screen and (min-width: 650px) and (max-width: 799px) {
 
  }
  
  /* Media query for extra small devices (max-width: 575px) */
  @media screen and (max-width: 650px) {
    footer {
        gap: 18px;
    }

    .footerRight {
        width: 50%;
    }

 .footerSocialIcon {
        color: white;
        font-size: 30px;
    }
    .footerLastQoute {
        gap: 3px;
        font-size: 12px;
        
    }
  }